import { dom_ready } from "../utils/dom_ready"

function text_nodes_under(el) {
  let n
  const a = []
  const walk = document.createTreeWalker(
    el,
    NodeFilter.SHOW_TEXT,
    null,
    false);
  while (n = walk.nextNode()) a.push(n);
  return a;
}

function replace_nodes() {
  const $body = document.getElementsByTagName('body')[0];
  const text_nodes = text_nodes_under($body);
  text_nodes.forEach($text => {
    // check whether we found a leaf node
    try {
      if (!$text.parentNode.children.length) {
        const new_content = $text.textContent
          .replaceAll('*innen', '<span aria-label=":">*</span>innen')
          .replaceAll('e*r', 'e<span aria-label=":">*</span>r')
          .replaceAll('*in', '<span aria-label=":">*</span>in');
        $text.parentNode.innerHTML = new_content;
      }
    } catch (e) {}
  })
}

dom_ready(replace_nodes);
