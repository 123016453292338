import { plugin_registry, PluginBase } from "nk-plugin-registry"

let INPAGE_DATA_ATTRIBUTE = 'inpagenav'


@plugin_registry.register('InPageNavigation')
class InPageNavigation extends PluginBase {
  constructor($node) {
    super($node);
    this.$node = $node;
    this.$list = $node.querySelector('ul');
  }

  _generate_links = () => {
    const $nodes = [...document.querySelectorAll(`[data-${INPAGE_DATA_ATTRIBUTE}]`)];
    const markup = $nodes.map((value, index, array)=>
      `<li>
         <a href="#${value.attributes["id"].value}">${ value.dataset[INPAGE_DATA_ATTRIBUTE] }</a>
       </li>`
    )
    return {
      count: $nodes.length,
      markup: markup.join('')
    }
  }

  update = () => {
    const links = this._generate_links();
    if (links.count > 0) {
      this.$list.innerHTML = links.markup;
    } else {
      this.$node.remove();
    }
  }
  loaded($node) {
    super.loaded($node);
    this.update();
  }
}

export { InPageNavigation }
