import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';

// generic imports
import "generics/inpagenavigation";
import "generics/references";
import "generics/friendly_gendering";

// import your plugins here.
import "citation/js/citation";
import "footer/js/footer";
import "header/js/header.js";
import "image/js/image.js";
import "youtube_video/js/youtube_video.js";
import "self_hosted_video/js/self_hosted_video.js";
import "stage/js/stage.js";

import "../../events/js/index";
import "../../sfb/js/list_filters.js";
import "../../events/js/database_list_filters.js"

plugin_registry.init();

